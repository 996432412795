@import "~antd/dist/antd.css";

#components-layout-demo-fixed-sider .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.2);
}

.site-layout .site-layout-background {
  background: #fff;
}

.logo {
  height: 32px;
  margin: 16px;
}

.logo h4 {
  color: white;
}

@primary-color: #1890ff; // primary color for all components
@link-color: #1890ff; // link color
@success-color: #52c41a; // success state color
@warning-color: #faad14; // warning state color
@error-color: #f5222d; // error state color
@font-size-base: 12px; // major text font size
@heading-color: rgba(0, 0, 0, 0.85); // heading text color
@text-color: rgba(0, 0, 0, 0.65); // major text color
@text-color-secondary: rgba(0, 0, 0, 0.45); // secondary text color
@disabled-color: rgba(0, 0, 0, 0.25); // disable state color
@border-radius-base: 2px; // major border radius
@border-color-base: #d9d9d9; // major border color
@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
  0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05); // major shadow for layers

.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.wrapper {
  display: grid;
  grid-template-columns: 25% 75%;
}

.form-item-label {
  padding-left: 10px;
}

.wrapper-half {
  display: grid;
  grid-template-columns: 50% 50%;
}

.wrapper-one-column {
  display: grid;
  grid-template-columns: 20% 50%;
}

.wrapper-dynamic-rows {
  display: grid;
  grid-template-columns: 80% 20%;
}

.w-100 {
  width: 100%;
}

.ant-table table {
  font-size: 12px;
}
.ant-table table {
  font-size: 12px;
}

.wrapper-half-orders {
  display: grid;
  grid-template-columns: 40% 60%;
}
